import {Form, Select, SelectProps} from "antd";
import {SelectionValue} from "../../../models/Common/SelectionValue";
export interface AreaFieldProps{
    className?:React.HTMLAttributes<HTMLDivElement>['className'];
    areas: Array<SelectionValue>;
    onChange?:(valued:string)=>void;
}
export function AreaField(props:AreaFieldProps){
    const orderedOptions = props.areas.sort((a, b) => a.label.localeCompare(b.label));
    return <>
        <Form.Item name={"areaField"} >
            <Select placeholder="Area"
                    className={props.className}
                    options={orderedOptions}
                    style={{width: '300px'}}
                    allowClear={true}
                    onChange={props.onChange}
            />
        </Form.Item>
    </>
}
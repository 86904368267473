import {PagedResponse} from "../Common/PagedResponse";

export interface PracticeFilesResponse extends PagedResponse<PracticeFile> {}

export interface PracticeFile{
    code:number;
    type:AttachmentType,
    filename:string,
    insertDate:string,
}

export interface PracticeFileRow extends PracticeFile{
    key:string
}

export interface UploadAttachment{
    filename:string
    attachmentType:AttachmentType
    scan?:boolean
}

export enum AttachmentType{
    INVOICE= "INVOICE",
    ESTIMATE= "ESTIMATE",
    LENS_COMPLAINCE= "LENS_COMPLAINCE",
    CAMBIO_VISUS= "CAMBIO_VISUS",
    VOUCHER= "VOUCHER",
    SIGNED_PIC="SIGNED_PIC",
    OTHER= "OTHER",
    DECLARATION="DECLARATION",
    PIC="PIC",
    DRAFT_INVOICE="DRAFT_INVOICE"
}
import {DatePicker} from "antd";
import React from "react";
import dayjs from "dayjs";

export interface DateFieldProps {
    large?:boolean;
    onChange?: (value: string | undefined) => void;
}

export function DateField(props: DateFieldProps) {

    const dateFormat = "DD/MM/YYYY"

    const maxDate = new Date();

    return <DatePicker style={props.large ? { width: '200px'} : {}} onChange={props.onChange} format={dateFormat} maxDate={dayjs(maxDate)}/>
}
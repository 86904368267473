import {PracticeType} from "../../../../../models/Practice/Practice";
import {
    Alert,
    Button,
    Col,
    Form, FormInstance,
    FormListFieldData,
    InputNumber,
    Modal,
    Row,
    Typography
} from "antd";
import {AddValue} from "../AddValue/AddValues";
import {AddPerformance} from "../AddPerformance/AddPerformance";
import React, {ReactElement, useEffect, useState} from "react";
import {PracticesHttpService} from "../../../../../services/Http/PracticesHttpService";
import {useParams} from "react-router-dom";
import {DeleteOutlined} from "@ant-design/icons"
import {OrderData, UploadEstimateBodyRequest} from "../../../../../models/Practice/PracticeDetail";
import {SelectionValue} from "../../../../../models/Common/SelectionValue";
import {isNil} from "lodash";

export interface PreventiveDialogProps{
    isModalOpen:boolean,
    onSubmit: (body: UploadEstimateBodyRequest) => Promise<void>;
    onCancel:() => void,
    agreement:PracticeType,
    integrationCode:string
    insuranceProviderId:number,
    locationCode: string;
}

const {Text} = Typography;

interface OrderForm {
    service: string;
    amount: number;
    amountRaw?: number;
}

interface EstimateForm {
    montatura?: number;
    lenti?: number;
    altro?: number;
    orders: Array<OrderForm>;
}

/*
    I computer fanno cacare a gestire i float point!
    Se non ci credi prova a fare: (0.1 + 0.2) == 0.3
 */
function floatEquals(num1: number, num2: number) {
    const tollerance = 0.00000001;

    return Math.abs(num1 - num2) <= tollerance;
}

export function PreventiveDialog(props: PreventiveDialogProps) {
    const { id } = useParams();
    const [form] = Form.useForm();

    const [services,setServices]   = useState<Array<SelectionValue>>([]);
    const [isSubmitting, setSubmitting] = useState(false);

    useEffect(() => {
        loadServices();
    }, []);

    const loadServices = async () => {
        let data;
        if (props.agreement === PracticeType.INDIRECT) {
            data = [
                {
                    value: "MTT",
                    label: "Montatura"
                },
                {
                    value: "4162",
                    label: "Lenti"
                },
                {
                    value: "ALT",
                    label: "Altro (accessori, …)"
                }
            ];
        } else if (isNil(id)) {
            const response = await PracticesHttpService.getServicesByGenericInfo(props.insuranceProviderId, props.locationCode);
            data = response.data;
        } else {
            const response = await PracticesHttpService.getServicesByClaimId(id.toString());
            data = response.data;
        }
        setServices(data);
    }

    const insertRawAmount = ["BLUEASSISTANCE", "INSALUTE"].includes(props.integrationCode.toUpperCase());
    const requireSplit = ["PREVIMEDICAL","CASPIE", "BLUEASSISTANCE", "INSALUTE"].includes(props.integrationCode.toUpperCase());

    const onCancel = () => {
        form.resetFields();
        props.onCancel();
    }

    const onFinish = async (data: EstimateForm) => {
        let note = "";
        if (requireSplit) {
            const total = calculateTotal(form);
            const totalSplit = calculateSplitTotal(form);

            if (!floatEquals(total, totalSplit)) {
                return;
            }

            note = [
                data.montatura ? `Montatura: ${data.montatura}` : null,
                data.lenti ? `Lenti: ${data.lenti}` : null,
                data.altro ? `Altro: ${data.altro}` : null
            ].filter(Boolean).join(', ');
        }

        const ordersToSend: Array<OrderData> = data.orders.map(o => {
            let service = services!.find(s => s.value == o.service);

            return {
                code: o.service,
                label: service!.label ?? "",
                quantity: 1,
                amount: o.amount,
                amountRaw: o.amountRaw
            }
        })

        const body: UploadEstimateBodyRequest  = {
            orders: ordersToSend,
            note
        }

        setSubmitting(true)
        try {
            await props.onSubmit(body);
            form.resetFields();
        } finally {
            setSubmitting(false);
        }
    }

    const modalRender = (dom: any) => {
        return <Form layout="vertical"
                     form={form}
                     name="preventiveDialog"
                     onFinish={onFinish}
                     initialValues={{ orders: [{}] }}
        >{dom}</Form>
    }

    const calculateTotal = (formInstance: FormInstance<EstimateForm>) => {
        const orders: Array<OrderForm> = formInstance.getFieldValue('orders');
        return orders.reduce((acc: number, o: OrderForm) => acc + (isNaN(o.amount) ? 0 : o.amount), 0);
    }

    const calculateSplitTotal = (formInstance: FormInstance<EstimateForm>) => {
        const montatura: number = formInstance.getFieldValue('montatura') ?? 0;
        const lenti: number = formInstance.getFieldValue('lenti') ?? 0;
        const altro: number = formInstance.getFieldValue('altro') ?? 0;

        return montatura + lenti + altro
    }

    /*
        Scusate, ma non so se esiste un modo migliore (non volendo definire un altro componente)
     */
    const renderRow = (key: string,
                       firstCol: ReactElement<any, any>,
                       secondCol: ReactElement<any, any>,
                       thirdCol: ReactElement<any, any>,
                       fourthCol: ReactElement<any, any>) => {
        return  <Row key={key} gutter={16}>
            <Col span={12}>
                {firstCol}
            </Col>
            { insertRawAmount && <Col span={5}>
                {secondCol}
            </Col> }
            <Col span={5}>
                {thirdCol}
            </Col>
            <Col span={2}>
                {fourthCol}
            </Col>
        </Row>
    }

    const renderOrder = (field: FormListFieldData, remove: (index: (number | number[])) => void) => {
        return renderRow(
            `order-${field.key}`,
            <AddPerformance performanceOptions={services} name={[field.name, 'service']}/>,
            <AddValue name={[field.name, 'amountRaw']}
                      otherValueName={[field.name, 'amount']}
                      compare={(value, otherValue) => value > otherValue}
                      errorMessage="Il prezzo di listino deve essere maggiore del prezzo scontato!"/>,
            <AddValue name={[field.name, 'amount']}
                      otherValueName={ insertRawAmount ? [field.name, 'amountRaw'] : null}
                      compare={(value, otherValue) => value < otherValue}
                      errorMessage="Il prezzo scontato deve essere minore del prezzo di listino!"/>,
            <Button onClick={() => remove(field.name)} danger>
                <DeleteOutlined />
            </Button>
        )
    }

    const renderSplit = () => {
        if (!requireSplit)
            return <></>

        return <>
            <hr style={{borderTop: 'grey'}}/>
            <Text strong>Split occhiale</Text>
            <Row style={{marginTop: '10px'}} gutter={16}>
                <Col span={10}>
                    <Text strong>Valore Montatura Preventivo</Text>
                </Col>
                <Col span={14}>
                    <Form.Item name="montatura">
                        <InputNumber min={0}
                                     addonAfter="€"
                                     className={"add-value-input-box"}
                                     decimalSeparator={","}
                                     controls={false} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={10}>
                    <Text strong>Valore Lenti Preventivo</Text>
                </Col>
                <Col span={14}>
                    <Form.Item name="lenti" style={{flex: 1}}>
                        <InputNumber min={0}
                                     addonAfter="€"
                                     className={"add-value-input-box"}
                                     decimalSeparator={","}
                                     controls={false} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={10}>
                    <Text strong>Valore Altro Preventivo</Text>
                </Col>
                <Col span={14}>
                    <Form.Item name="altro">
                        <InputNumber min={0}
                                     addonAfter="€"
                                     className={"add-value-input-box"}
                                     decimalSeparator={","}
                                     controls={false} style={{width: '100%'}}/>
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item<EstimateForm> shouldUpdate>
                {(formInstance) => {
                    const splitTotal = calculateSplitTotal(formInstance);
                    const total = calculateTotal(formInstance);

                    return <>
                        <Row gutter={16}>
                            <Col span={10}>
                                <Text strong>Totale Valore Preventivo</Text>
                            </Col>
                            <Col span={14}>
                                {splitTotal}
                            </Col>
                        </Row>
                        {!floatEquals(total, splitTotal) && <Alert type="error"
                                                                   message="Il totale del preventivo deve essere uguale al totale splittato"
                                                                   style={{marginTop: '10px'}}/>}
                    </>
                }}
            </Form.Item>
        </>
    }

    return <>
        <Modal width={820}
               closable
               open={props.isModalOpen}
               title="Preventivo"
               okButtonProps={{ loading: isSubmitting, autoFocus: true, htmlType: 'submit' }}
               destroyOnClose
               onCancel={onCancel}
               modalRender={modalRender}
               footer={[
                   <Button key="cancel" onClick={onCancel}>Annulla</Button>,
                   <Button key="ok" type="primary" loading={isSubmitting} onClick={() => form.submit()}>Ok</Button>,
                   props.integrationCode.includes('GENERALI') && <div key="footer-text" style={{ marginTop: '10px', fontSize: '14px', textAlign: 'left' }}>
                       <strong>Attenzione</strong>: ricordiamo che la Garanzia è sempre oggetto di rimborso da parte di Generali,
                       pertanto il suo eventuale importo dovrà sempre essere inserito all’interno del preventivo totale
                       aggiungendolo direttamente al totale <strong>Lenti</strong> o <strong>Lenti + Montatura</strong>
                   </div>
               ]}

        >
            {
                renderRow(
                    "header",
                    <Text strong>Prestazioni</Text>,
                    <Text strong>Prezzo listino</Text>,
                    <Text strong>Prezzo scontato</Text>,
                    <Text strong>Azioni</Text>
                )
            }
            <Form.List name="orders"
                       rules={[
                           {
                               validator: async (_, orders) => {
                                   if (!orders || orders.length < 1) {
                                       return Promise.reject(new Error('Devi inserire almeno una prestazione!'));
                                   }
                               }
                           }
                       ]}>
                {(fields, {add, remove}, {errors}) => (
                    <div style={{marginTop: "10px"}}>
                        { fields.map(field => renderOrder(field, remove)) }
                        <Button block type="dashed" onClick={add} style={{marginBottom: '24px'}}>Aggiungi prestazioni</Button>
                        <Form.ErrorList errors={errors} />
                    </div>
                )}
            </Form.List>
            <Form.Item<EstimateForm> shouldUpdate>
                {(formInstance) => {
                    const orders: Array<OrderForm> = formInstance.getFieldValue('orders');

                    const totalAmount = calculateTotal(formInstance);
                    const totalAmountRaw = orders.reduce((acc: number, o: OrderForm) => acc + (o?.amountRaw || 0), 0);

                    return renderRow(
                        "totals",
                        <Text strong>Totale preventivo</Text>,
                        <Text strong>{totalAmountRaw.toFixed(2)}</Text>,
                        <Text strong>{totalAmount.toFixed(2)}</Text>,
                        <></>
                    )
                }}
            </Form.Item>
            { renderSplit() }
        </Modal>
    </>
}

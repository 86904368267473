import {Button, Flex, Typography} from "antd";
import {CloseCircleFilled} from "@ant-design/icons";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {PracticeNote, PracticeNotePutBody} from "../../../models/Practice/PracticeNote";
import {MessageTextBox} from "../../Common/MessageTextBox/MessageTextBox";
import {isNil} from "lodash";
import {NotificationService} from "../../../services/NotificationService";
import {SpinLuxottica} from "../../Common/SpinLuxottica/SpinLuxottica";
import {Employee} from "../../../models/Auth/Employee";
import {AuthService} from "../../../services/AuthService";
import {NoteList} from "../NoteList";
import {PracticeDetail} from "../../../models/Practice/PracticeDetail";

export interface NoteColumnProps {
    practiceDetail: PracticeDetail;
    onClickExit: () => void
    reloadDetail: () => void;
}

export function NoteColumn(props:NoteColumnProps){
    const { Text } = Typography;
    let { id } = useParams();

    const [data, setData] = useState<PracticeNote[]>()
    const [isLoading,setLoading] = useState<boolean>(true);

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setLoading(true);
        try {
            const response = await PracticesHttpService.getPracticeNotes(id)
            setData(response.data.items);
        } finally {
            setLoading(false);
        }
    };

    const onClickButton = async (from:string, messageText:string) => {
        if (isNil(id)) {
            return;
        }
        if (messageText.trim().length === 0) {
            NotificationService.getInstance().openNotificationWarning("Messaggio non valido");
            return;
        }
        let operator:Employee|null = AuthService.getOperatorOnline();
        let body:PracticeNotePutBody={
            operator: operator?.name ?? "",
            text: messageText,
        }
        try {
            await PracticesHttpService.savePracticeNotes(body, id)
            NotificationService.getInstance().openNotificationSuccess("Nota salvata","");
            loadData();
        } catch (_) {
            NotificationService.getInstance().openNotificationError("Impossibile salvare la nota")
        }
    };

    return <div className={"practices-details-columns"}>
            <Flex vertical justify={"center"} align={"center"} gap={"small"}>
                <Flex justify={"space-between"} align={"center"} gap={"large"} className={"width-100"}>
                    <Text strong>Note</Text>
                    <Button shape="circle"  icon={<CloseCircleFilled style={{fontSize: '30px'}} />}  onClick={props.onClickExit}/>
                </Flex>
                <SpinLuxottica loading={isLoading}></SpinLuxottica>

                <NoteList data={data ?? []}/>
                <Flex vertical className={"width-100"}>
                    <MessageTextBox key="noteMessageTextBox" onClickButton={onClickButton} isNote={true} practiceDetail={props.practiceDetail} reloadDetail={props.reloadDetail}></MessageTextBox>
                </Flex>

            </Flex>

    </div>
}
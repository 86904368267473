import {Button, Flex, Modal} from "antd";
import {useModal} from "../../utils/modals";
import {PracticesHttpService} from "../../services/Http/PracticesHttpService";
import {useParams} from "react-router-dom";
import {Employee} from "../../models/Auth/Employee";
import {AuthService} from "../../services/AuthService";
import {PracticeProgressStatus} from "../../models/Practice/Practice";
import {isNil} from "lodash";
import React, {useState} from "react";
import {useClaimStatus, claimClosed} from "../PracticesDetails/ClaimStatusProvider";
import {ClosingReasonPractice} from "../../models/Practice/PracticeDetail";

export interface AdminPracticeMenuProps {
    reloadData: () => void;
}

export function AdminPracticeMenu(props: AdminPracticeMenuProps) {
    const { id } = useParams();

    const claimStatus = useClaimStatus();

    const [isLoading, setLoading] = useState(false)

    const [selectedOption, setSelectedOption] = useState<string | null>(null);

    const {
        isOpen,
        open,
        close
    } = useModal();

    const handleOptionSelect = (id: string) => {
        setSelectedOption(id);
    };

    const getButtonStyle = (id: string) => {
        return {
            backgroundColor: selectedOption === id ? 'lightgrey' : 'white',
        };
    }

    const makeRequest = async (event: React.FormEvent) => {
        if (isNil(id)) {
            return;
        }
        event.preventDefault();
        if (isNil(selectedOption)) return;
        setLoading(true);
        let operator:Employee|null = AuthService.getOperatorOnline();
        try {
            if (selectedOption === 'CLOSE') {
                await PracticesHttpService.closePractice({
                    adminExecution: true,
                    reason:ClosingReasonPractice.EMPTY
                }, id );
            } else if (selectedOption === 'DELETE_PIC') {
                await PracticesHttpService.forceDeletePic(id);
            } else {
                await PracticesHttpService.saveForceProceedNext(id, {
                    metadata: { operator:operator?.name ?? "" },
                    event: selectedOption
                })
            }
            props.reloadData();
        } catch (e) {
            console.error(e);
        } finally {
            setLoading(false);
        }
        setSelectedOption(null);
        close();
    }

    const closeModal = () => {
        setSelectedOption(null);
        close();
    }

    return <>
        <Button disabled={claimClosed(claimStatus)} type={"primary"} onClick={open}>Admin menu</Button>
        <Modal title="Menu admin per la pratica" open={isOpen} onOk={makeRequest} onCancel={closeModal}>
            <Flex vertical>
                <Button loading={isLoading} onClick={() => handleOptionSelect(PracticeProgressStatus.DOCUMENTAZIONE_INVIATA)} style={getButtonStyle(PracticeProgressStatus.DOCUMENTAZIONE_INVIATA)}>Documentazione inviata</Button>
                <Button loading={isLoading} onClick={() => handleOptionSelect(PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE)} style={getButtonStyle(PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE)}>Errore in documentazione</Button>
                <Button loading={isLoading} onClick={() => handleOptionSelect(PracticeProgressStatus.PIC_AUTORIZZATA)} style={getButtonStyle(PracticeProgressStatus.PIC_AUTORIZZATA)}>Autorizzazione PIC</Button>
                <Button loading={isLoading} onClick={() => handleOptionSelect(PracticeProgressStatus.PIC_NON_AUTORIZZATA)} style={getButtonStyle(PracticeProgressStatus.PIC_NON_AUTORIZZATA)}>PIC non autorizzata</Button>
                <Button loading={isLoading} onClick={() => handleOptionSelect('DELETE_PIC')} style={getButtonStyle('DELETE_PIC')}>Annulla PIC</Button>
                <Button loading={isLoading} onClick={() => handleOptionSelect(PracticeProgressStatus.FATTURA_INVIATA)} style={getButtonStyle(PracticeProgressStatus.FATTURA_INVIATA)}>Fattura inviata</Button>
                <Button loading={isLoading} onClick={() => handleOptionSelect(PracticeProgressStatus.ERRORE_IN_FATTURA)} style={getButtonStyle(PracticeProgressStatus.ERRORE_IN_FATTURA)}>Errore in fattura</Button>
                <Button loading={isLoading} onClick={() => handleOptionSelect('CLOSE')} style={getButtonStyle('CLOSE')}>Chiusura pratica</Button>
            </Flex>
        </Modal>
    </>
}
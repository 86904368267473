import {useNavigate} from "react-router-dom";
import {useState} from "react";
import { signIn, confirmSignIn } from 'aws-amplify/auth';
import SMSForm from "./SMSForm/SMSForm";
import UserPasswordForm from "./UserPasswordForm/UserPasswordForm";
import NewPasswordForm from "./NewPasswordForm/NewPasswordForm";
import "./LoginForm.css"
import LogoSalmoiraghiVigano from '../../../assets/SalmoiraghiVigano.svg'
import LogoGrandVision from '../../../assets/GrandVision.svg'
import LogoGlasses from '../../../assets/ShadedGlasses.svg'

// che schifo
import {AuthSignInOutput} from '@aws-amplify/auth/dist/esm/types/outputs';
import {AuthUserAttributeKey} from '@aws-amplify/auth/dist/esm/types/models';
import {CognitoService} from "../../../services/CognitoService";
import {Paths} from "../../../models/Paths";
import {Alert, Button, Col, Flex, Image,  Row, Typography} from "antd";
import RecoverPasswordForm from "./RecoverPasswordForm";
import {ListOfOperator} from "./ListOfOperators/ListOfOperator";
import {Employee} from "../../../models/Auth/Employee";
import {isNil} from "lodash";
import {AuthService} from "../../../services/AuthService";
import {useTranslation} from "react-i18next";

export type SubmitFunction<T> = (values: T) => Promise<void>;

export interface UserPasswordFormData {
    username?: string;
    password?: string;
}

const confirmSignInOnCognito = async ({challengeResponse}: {challengeResponse: string}) => {
    return await confirmSignIn({ challengeResponse });
}

const LoginForm = () => {
    const { t } = useTranslation();
    const {Text} = Typography;
    const navigate = useNavigate();
    const [signInStep, setSignInStep] = useState('LOGIN');
    const [infoMessage, setInfoMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showListOfOperators, setShowListOfOperators] = useState<boolean>(false);
    const [selectedOperator, setSelectedOperator] = useState<Employee|undefined>();

    async function handleAWSResponse(response: AuthSignInOutput<AuthUserAttributeKey>) {
        const {isSignedIn, nextStep} = response;
        if (isSignedIn) {
            await CognitoService.get().doLogin();
            setShowListOfOperators(true);
            return;
        }

        setSignInStep(nextStep.signInStep)
    }
    function onSelectedOperator(option:any,value:Employee|undefined){
        if(isNil(value) === false) {
            setSelectedOperator(value);
        }
    }
    function goHome(){
        AuthService.saveOperator(selectedOperator);
        navigate(Paths.HOME);
    }
    const handleSubmit: SubmitFunction<UserPasswordFormData> = async (values): Promise<void> => {
        if (values.username == undefined || values.password == undefined) return;

        let response
        try {
            response = await signIn({ username: values.username, password: values.password });
            sessionStorage.setItem('username', values.username);
            setErrorMessage(null);
        } catch (error) {
            if (typeof error === "string") {
                setErrorMessage(error.toUpperCase());
            } else if (error instanceof Error) {
                const translated = t(error.name);
                setErrorMessage(translated == error.name ? error.message : translated);
            }
            return;
        }

        handleAWSResponse(response);
    }

    const handleNewPassword: SubmitFunction<{newPassword: string}> = async (values) => {
        const response = await confirmSignInOnCognito({ challengeResponse: values.newPassword });
        handleAWSResponse(response);
    }

    const handleTotp: SubmitFunction<{totp: string}> = async (values) => {
        let response
        try {
            response = await confirmSignInOnCognito({ challengeResponse: values.totp });
            setErrorMessage(null);
        } catch (error) {
            if (typeof error === "string") {
                setErrorMessage(error.toUpperCase());
            } else if (error instanceof Error) {
                const translated = t(error.name);
                setErrorMessage(translated == error.name ? error.message : translated);
            }
            return;
        }

        handleAWSResponse(response);
    }

    const recoverPassword = () => {
        setSignInStep('RECOVER_PASSWORD');
    }

    const completeForgotPasswordProcess = () => {
        setInfoMessage('Procedura di recupero password completata')

        setTimeout(() => {
            setSignInStep('LOGIN')
            setInfoMessage(null)
        }, 2000)
    }

    let form;

    if (signInStep == 'LOGIN') {
        form = (
            <>
                <UserPasswordForm errorMessage={errorMessage} onSubmit={handleSubmit}/>
                <Button type="link" style={{width: '100%'}} onClick={recoverPassword}>Hai dimenticato la password?</Button>
            </>
        )
    } else if (signInStep == 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        form = (
            <NewPasswordForm onSubmit={handleNewPassword}/>
        )
    } else if (signInStep == 'CONFIRM_SIGN_IN_WITH_SMS_CODE' || signInStep == 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE') {
        form = (
            <SMSForm errorMessage={errorMessage} onSubmit={handleTotp}/>
        )
    } else if (signInStep == 'RECOVER_PASSWORD') {
        form = (
            <RecoverPasswordForm handleComplete={completeForgotPasswordProcess}/>
        )
    }

    return (
        <Row className="login-container">
            <Col span={10}>
                <Image preview={false} width={500} height={250} src={LogoGlasses} ></Image>
            </Col>
            <Col span={14}>
                <Flex vertical justify={"center"} align={"center"} gap={"large"}>
                    <Flex vertical justify={"center"} align={"center"} gap={"small"}>
                        <Image preview={false} width={300} height={50} src={LogoSalmoiraghiVigano} ></Image>
                        <Image preview={false} width={330} height={60} src={LogoGrandVision} ></Image>
                    </Flex>
                    <div className="login-form">
                        {infoMessage != null && <Alert message={infoMessage} type="info" />}
                        {showListOfOperators === false && form}

                        { showListOfOperators === true && <Flex gap={"middle"} justify={"center"} align={"center"}>
                                {/*<Text> Seleziona operatore </Text>*/}
                                <ListOfOperator onSelected={onSelectedOperator} id={"1"}></ListOfOperator>
                                <Button onClick={goHome} disabled={isNil(selectedOperator) === true}>Seleziona</Button>
                            </Flex>
                        }
                    </div>
                </Flex>
            </Col>
        </Row>
    )
}

export default LoginForm;

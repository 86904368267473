import Title from "antd/es/typography/Title";
import {Button, Flex} from "antd";
import {
    PracticeProgressStatus,
    PracticeType
} from "../../../models/Practice/Practice";
import {ClosePracticesDialog} from "../ClosePracticesDialog/ClosePracticesDialog";
import {useState} from "react";
import {
    ClaimStatuses,
    ClosingReasonPractice,
    PracticeProgress,
    ProceedNextRequest
} from "../../../models/Practice/PracticeDetail";
import {PracticesHttpService} from "../../../services/Http/PracticesHttpService";
import {useParams} from "react-router-dom";
import {isNil} from "lodash";
import {Utils} from "../../../services/Utils";
import {Employee} from "../../../models/Auth/Employee";
import {AuthService} from "../../../services/AuthService";
import './RightColumn.css';
import {NotificationService} from "../../../services/NotificationService";
import {ShowProgressInfo} from "./components/ShowProgressInfo";
import {useClaimStatus, claimClosed} from "../ClaimStatusProvider";

export interface RightColumnProps{
    operator:string,
    date:string,
    progress: PracticeProgress[] | undefined,
    onClickChatButton:()=>void,
    manage:PracticeType,
    claimStatus: ClaimStatuses
    onCloseModal:()=>void
}
export function RightColumn(props:RightColumnProps){
    const [isModalOpen, setModalOpen] = useState<boolean>(false)
    let { id } = useParams();

    const claimStatus = useClaimStatus();

    const showModalClose = () => {
        setModalOpen(true);
    };

    const hideModalClose = () => {
        setModalOpen(false);
    };

    const onClosePractice = async (value:ClosingReasonPractice|undefined) => {
        hideModalClose();
        if (isNil(id)) return;
        if (value == undefined) return;
        if(value === ClosingReasonPractice.PIC_NOT_AUTHORIZED_PURCHASED) {
            let operator:Employee|null = AuthService.getOperatorOnline();
            let body:ProceedNextRequest = { metadata :{operator:operator?.name ?? "" }, event:PracticeProgressStatus.ACQUISTO_EFFETTUATO }
            try {
                await PracticesHttpService.saveProceedNext(id ?? "",body);
                props.onCloseModal()
            } catch (_) {
                NotificationService.getInstance().openNotificationError("Operazione non riuscita")
            }
        } else {
            try {
                await PracticesHttpService.closePractice({reason:value}, id );
                props.onCloseModal()
            } catch (_) {
                NotificationService.getInstance().openNotificationError("Operazione non riuscita")
            }
        }

    };

    const getProgressData = (status:PracticeProgressStatus): PracticeProgress | undefined => Utils.getProgressData(status, props.progress);

    const getDirectUi = () => {
        const docInviata = getProgressData(PracticeProgressStatus.DOCUMENTAZIONE_INVIATA);
        const fatturaInviata = getProgressData(PracticeProgressStatus.FATTURA_INVIATA);
        const acquistoEffettuato = getProgressData(PracticeProgressStatus.ACQUISTO_EFFETTUATO);
        const lastProgress = Utils.getLastProgressData(props.progress);
        const picNonAutorizzata = lastProgress?.name === PracticeProgressStatus.PIC_NON_AUTORIZZATA ? lastProgress : undefined;
        const errorInDocumentazione = lastProgress?.name === PracticeProgressStatus.ERRORE_IN_DOCUMENTAZIONE ? lastProgress : undefined;
        const errorInFattura = lastProgress?.name === PracticeProgressStatus.ERRORE_IN_FATTURA ? lastProgress : undefined;

        return <>
            <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.CONTATTATO}
                                  progressData={getProgressData(PracticeProgressStatus.CONTATTATO)}
                                  labelAsButton={true}
                                  onLabelClick={props.onClickChatButton}
                />
            </div>
            <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.PRESO_APPUNTAMENTO}
                                  progressData={getProgressData(PracticeProgressStatus.PRESO_APPUNTAMENTO)}/>
            </div>
            { isNil(docInviata) && <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE}
                                  progressData={getProgressData(PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE)}
                                  errorProgressData={errorInDocumentazione} />
            </div> }
            <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.DOCUMENTAZIONE_INVIATA}
                                  progressData={docInviata}
                                  errorProgressData={docInviata ? errorInDocumentazione : undefined} />
            </div>
            <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.PIC_AUTORIZZATA}
                                  progressData={getProgressData(PracticeProgressStatus.PIC_AUTORIZZATA)}
                                  errorProgressData={picNonAutorizzata} />

            </div>
            { !isNil(acquistoEffettuato) && <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.ACQUISTO_EFFETTUATO}
                                  progressData={acquistoEffettuato}/>
            </div> }
            { isNil(fatturaInviata) && <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.FATTURA_DA_INVIARE}
                                  progressData={getProgressData(PracticeProgressStatus.FATTURA_DA_INVIARE)}
                                  errorProgressData={errorInFattura} />
            </div> }
            <div className={"margin-y-5px"}>
                <ShowProgressInfo progressStatus={PracticeProgressStatus.FATTURA_INVIATA}
                                  progressData={fatturaInviata}
                                  errorProgressData={fatturaInviata ? errorInFattura : undefined} />
            </div>
        </>;
    }

    const getIndirectUi = () => <>
        <div className={"margin-y-5px"}>
            <ShowProgressInfo progressStatus={PracticeProgressStatus.CONTATTATO}
                              progressData={getProgressData(PracticeProgressStatus.CONTATTATO)}
                              labelAsButton={true}
                              disabled={claimClosed(claimStatus)}
                              onLabelClick={props.onClickChatButton}
            />
        </div>
        <div className={"margin-y-5px"}>
            <ShowProgressInfo progressStatus={PracticeProgressStatus.PRESO_APPUNTAMENTO}
                              progressData={getProgressData(PracticeProgressStatus.PRESO_APPUNTAMENTO)}/>
        </div>
        <div className={"margin-y-5px"}>
            <ShowProgressInfo progressStatus={PracticeProgressStatus.ACQUISTO_EFFETTUATO}
                              progressData={getProgressData(PracticeProgressStatus.ACQUISTO_EFFETTUATO)}/>
        </div>

    </>;

    const closeButton = () => {
        if (props.claimStatus === ClaimStatuses.CLOSED) return <></>

        return <>
            <div className="bottom-container">
                <Button type={"primary"} className={"button"} onClick={showModalClose}>Chiudi pratica</Button>
            </div>
            <ClosePracticesDialog handleCancel={hideModalClose}
                                  handleOk={onClosePractice}
                                  isModalOpen={isModalOpen}
                                  manage={props.manage}
            ></ClosePracticesDialog>
        </>
    };

    return <div className={"practices-details-columns practices-detail-right-column"}>
        <Title level={5} className={"title"}>Avanzamento</Title>
        <div className={"margin-left-5px body-state-column"}>
            {props.manage === PracticeType.DIRECT && getDirectUi()}
            {props.manage === PracticeType.INDIRECT && getIndirectUi()}
        </div>
        {closeButton()}
    </div>
}

import {PracticeProgress} from "../../../../models/Practice/PracticeDetail";
import {isNil} from "lodash";
import {PracticeProgressStatus} from "../../../../models/Practice/Practice";
import {Button, Flex, Typography} from "antd";
import {Utils} from "../../../../services/Utils";
import {IconState} from "../../../Common/IconsState/IconState";

export interface ShowProgressInfoProps {
    disabled?: boolean;
    progressStatus: PracticeProgressStatus;
    progressData: PracticeProgress | undefined;
    labelAsButton?: boolean;
    onLabelClick?: () => void;
    errorProgressData?: PracticeProgress;
}

function getProgressInfoLabel(progressStatus: PracticeProgressStatus) {
    switch (progressStatus) {
        case PracticeProgressStatus.CONTATTATO:
            return "Contatti";
        case PracticeProgressStatus.PRESO_APPUNTAMENTO:
            return "Appuntamento"
        case PracticeProgressStatus.DOCUMENTAZIONE_INVIATA:
            return "Doc. inviata"
        case PracticeProgressStatus.PIC_AUTORIZZATA:
            return "Autorizzazione PIC"
        case PracticeProgressStatus.FATTURA_INVIATA:
            return "Fattura inviata"
        case PracticeProgressStatus.FATTURA_DA_INVIARE:
            return "Fattura confermata"
        case PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE:
            return "Doc. confermata"
        case PracticeProgressStatus.ACQUISTO_EFFETTUATO:
            return "Acquisto effettuato"
        default:
            return ""
    }
}

function getProgressInfoDateLabel(progressStatus: PracticeProgressStatus, errorProgressData: PracticeProgress) {
    switch (progressStatus) {
        case PracticeProgressStatus.CONTATTATO:
            return "Data ultimo contatto";
        case PracticeProgressStatus.PRESO_APPUNTAMENTO:
            return "Ultima modifica"
        case PracticeProgressStatus.DOCUMENTAZIONE_DA_INVIARE:
            return "Data conferma documentazione"
        case PracticeProgressStatus.DOCUMENTAZIONE_INVIATA:
            return "Data invio documentazione"
        case PracticeProgressStatus.PIC_AUTORIZZATA:
            if (errorProgressData?.name === PracticeProgressStatus.PIC_NON_AUTORIZZATA) {
                return "Ultima modifica";
            }
            return "Data autorizzazione PIC"
        case PracticeProgressStatus.FATTURA_DA_INVIARE:
            return "Data conferma fattura"
        case PracticeProgressStatus.FATTURA_INVIATA:
            return "Data invio fattura"
        case PracticeProgressStatus.ACQUISTO_EFFETTUATO:
            return "Data acquisto"
        default:
            return ""
    }
}

export function ShowProgressInfo({
                                     progressStatus,
                                     progressData,
                                     labelAsButton = false,
                                     onLabelClick,
                                     errorProgressData,
                                     disabled
                                 }: ShowProgressInfoProps) {
    const { Text } = Typography;

    const isChecked = !isNil(progressData);
    const operator = errorProgressData ? errorProgressData?.metadata?.operator : progressData?.metadata?.operator;

    const completedAt = errorProgressData ? errorProgressData?.completedAt : progressData?.completedAt;

    const renderDate = (date: number | undefined) => {
        if (isNil(date)) return "";
        return Utils.getDatetimeItFormatFromTimestamp(date * 1000)
    }

    let label;
    if (labelAsButton) {
        label = <Button disabled={disabled} type={"primary"} className={"margin-left-5px"}
                        onClick={onLabelClick}>{getProgressInfoLabel(progressStatus)}</Button>
    } else {
        label = <Text strong={true}>{getProgressInfoLabel(progressStatus)}</Text>;
    }

    const inError = !isNil(errorProgressData) &&
        (errorProgressData.name == PracticeProgressStatus.PIC_NON_AUTORIZZATA ||
            (!isNil(progressData) && errorProgressData.completedAt > progressData.completedAt));

    return <>
        <Flex align="center" gap={5}>
            <IconState progressStatus={progressStatus} isChecked={isChecked} inError={inError}/>
            {label}
        </Flex>
        <Flex vertical>
            {
                !isNil(completedAt) && <>
                    <Text strong={true}>{getProgressInfoDateLabel(progressStatus, errorProgressData!)}</Text>
                    <Text>{renderDate(completedAt)}</Text>
                </>
            }
            {
                !isNil(operator) && <>
                    <Text strong={true}>Operatore</Text>
                    <Text>{operator}</Text>
                </>
            }
        </Flex>
    </>
}
import {Form, Select} from "antd";
import {PracticeProgressStatus} from "../../../../models/Practice/Practice";
import "./Filters.css"
import {useTranslation} from "react-i18next";

export interface FilterStateProps{
    onChange?:(value:PracticeProgressStatus)=>void;
}
export function FilterState(props:FilterStateProps) {
    const { t, i18n } = useTranslation();

    const optionsList = getFilterStateOptions();

    function getFilterStateOptions(){
        const practiceProgressStatusKeys: string[] = Object.keys(PracticeProgressStatus);
        let options :{value: number|string, label: string}[]|undefined = practiceProgressStatusKeys.map((element:string)=>{
            return {value: PracticeProgressStatus[element as keyof typeof PracticeProgressStatus], label:t(element)  }//
        })
        return options;
    }

    const handleChange = (value: PracticeProgressStatus) => {
        if (props.onChange) {
            props.onChange(value)
        }
    };

    return <>
        <Form.Item name={"state"}>
            <Select
                placeholder="Stato avanzamento"
                className={"filter"}
                options={optionsList}
                onChange={handleChange}
                allowClear={true}

            />
        </Form.Item>
</>
}